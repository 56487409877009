.geral-container-part3{
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: url('../../../../assets/textura.png') no-repeat center center, url('../../../../assets/degradebranco.png');
    background-size: cover;
    background-blend-mode: screen;
}

.text-left-container-part3 p:first-child{
    font-size: 5rem;
    color: #007881;
    line-height: 5rem;
    font-weight: 700;
    font-family: Agenda-Bold;
    padding-bottom: 20px;
    text-align: start;
}

.text-left-container-part3{
    display: flex;
    flex-direction: column;
    /* flex: 1 auto; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* background-color: blue; */
    /* background-color: orange;
     */
}

.text-left-container-part3 p:last-child{
    font-size: 3rem;
    font-weight: 400;
    font-family: 'Euphemia UCAS';
    line-height: 4rem;
    text-align: start;
}
.right-container-part3 {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-container-part3 img {
    width: 50%;
    height: auto;
    /* transition: transform 450ms; */
}

/* .right-container-part3 img:hover {
    transform: scale(1.1)
} */

.left-container-part3 {
    margin-top: 30px;
    height: 100%;
    width: 50%;
    padding-left: 18%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width: 1024px) {
    .geral-container-part3 {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .right-container-part3 {
        margin-bottom: 30px;
        width: 100%;
    }

    .left-container-part3 {
        width: 100%;
    }
}
@media (max-width: 430px) {
    .geral-container-part3{
        height: 50vh;
    }

    .cartao-container img {
        height: auto;
        width: 70%;
    }

    .text-left-container-part3 .top-text-part3 p {
        font-size: 5.4rem;
        line-height: 5rem;
    }

    .text-left-container-part3 .bottom-text-part3 p{
        font-size: 3em;
        line-height: 1.5em;
    }

    .left-container-part3 {
        padding-left: 15px;
    }
}

@media screen and (max-width: 650px) {
    .geral-container-part3{
        height: 100%;
    }
    /* .right-container-part3 img {
        width: 60%;
    } */
}

@media screen and (max-width: 400px) and (max-height: 640px) {
    .geral-container-part3 {
        height: 100%;
    }

    .right-container-part3 img {
        width: 70%;
    }
}