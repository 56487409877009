footer {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #038B97;
    color: white;
    font-family: 'Nexa-Light';
    line-height: 1.6rem;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
}

@media screen and (max-width: 430px){
    footer {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}