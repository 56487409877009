


.barras-container {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
}

.barra2-container img:last-child{
    margin-left: 20px;
}

.geral-container-part5 {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #03656E;
}

.atendimento-container2 {
    padding-top: 10px;
}

.atendimento-container2, 
.numero-contato-container2,
.email-contato-container2 {
    display: flex;
    justify-content: center;
}

.atendimento2{
    color: white;
    font-size: 2.4rem;
    font-family: NexaHeavy;
}

.numero-contato {
    color: white;
    font-family: 'Agenda-bold';
    font-size: 3.5rem;
    line-height: 4.3rem;
}

.email-contato-container2 {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #4AB8A8;
}

.redes-sociais-container {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
}

.content1-container,.content2-container {
    display: flex;
    flex-direction: column;
}

.links {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.links a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 5px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-family: NexaRegular;
    text-align: center;
}

.links img {
    padding-top: 25px;
}

.left-links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-links {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mapa-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.endereco-frotabank2 {
    text-align: center;
    color: white;
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: 'NexaRegular';
    padding-top: 15px;
}

.geral-container-mobile {
    display: flex;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-around;
    background-color: #03656E;
    flex-direction: column;
}

.links-redes-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.content1-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    /* align-items: flex-start; */
}

.content2-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.links-mobile {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
}

.links-mobile a {
    text-decoration: none;
    color: #4AB8A8;
    padding-bottom: 3px;
    font-size: medium;
}

.redes-sociais-container-mobile {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    padding-right: 10px;
}

.redes-sociais-container-mobile a {
    padding-bottom: 10px;
}

.mapa-container-mobile a{
    width: 80%;
    height: 90px;
}

.mapa-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.endereco-container-mobile {
    text-align: center;
    color: white;
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-family: 'NexaRegular';
    padding-top: 15px;
}

.mapa-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    justify-content: center;
}

.mapa{
    margin-top: 20px;
    background-image: url('../../../../assets/gmaps.png');
    height: 120px;
    width: 250px;
    align-self: center;
    justify-self: center;
}

.left-links a:hover,
.right-links a:hover,
.links-mobile a:hover {
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 300px){
    .geral-container-mobile {
        width: 100%;
        justify-content: space-between;
        padding-left: 10px;
    }

    .redes-sociais-container-mobile {
        width: max-content;
        align-items: flex-end;
        width: 100%;
    }
    .redes-sociais-container-mobile img{
        padding-right: 10px;
        height: 40px;
        width: auto;
    }

    .content1-mobile {
        width: 50%;
        justify-content: space-around;
    }

    .barra-container-mobile img{
        padding-right: 10px;
        width: 100%;
    }

    .barra-container-mobile2 img {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .content2-mobile {
        width: 50%;
    }

    .barras-container-mobile {
        flex-direction: column;
    }
    
    .content2-container .barra2-container,
     .links img {
        display: none;
    }

    .email-contato-container {
        padding-bottom: 30px;
    }

    .redes-sociais-container {
        flex-direction: column;
        align-self: flex-end;
    }

    .redes-sociais-container img {
        height: 20px;
        width: auto;
        margin-bottom: 10px;
    }

    .content1-container .barra2-container img,
    .mapa-container2 .barra2-container img{
        width: 100px;
    }
}

 @media (max-width: 1024px){
    .pre-footer-container {
        padding-top: 20px;
        height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .links-container {
        padding-top: 20px;
    }

    .links-container .left-links-container a, .links-container .right-links-container a {
        padding-bottom: 5px;
    }

    .atendimento-container{
        margin-bottom: 2px;
        width: 100vw;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
    .atendimento-container .atendimento {
        font-size: 4rem;
        color: black;
    }
    .right-links-container{
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .mapa-container {
        padding-bottom: 20px;
    }
} 

@media screen and (max-width: 770px){
    .right-links-container img, .left-links-container img {
        visibility: hidden;
    }

    .redes-sociais-icons {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .email-contato-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: max-content;
        background-color: #4AB8A8;
        margin-bottom: 30px;
        padding: 10px;
    }

    .email-contato-text {
        color: white;
        font-size: 13px;
        font-family: Agenda-Bold;
    }

    .pre-footer-container .contato-container .numero-contato-container {
        background-color: white;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pre-footer-container .contato-container .numero-contato {
        color: #037983;
        font-size: 37px;
    }

    .mapa-container {
        visibility: hidden;
    }
}

@media screen and (max-width: 430px) {
   .redes-sociais-icons img {
       height: 20px;
       width: auto;
   }

    
    .redes-sociais-icons {
        justify-content: space-around;
        width: 100vw;
        padding: 0px;
    }

    .links-container {
        height: 30%;
        width: 100%;
        justify-content: center;
        padding: 0px;
        max-width: max-content;
        font-size: 1rem;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .right-links-container{
        transform: translate(-20%,0px);
        margin-right: 10px;
        width: max-content;
        margin-bottom: 10px;
    }
    .left-links-container{
        padding-left: 20px;
        margin-bottom: 10px;
    }

    .redes-sociais-icons {
        height: max-content;
    }
}

@media screen and (max-width: 400px) and (max-height: 1000px) {
    .pre-footer-container {
        height: 80vh;
    }
}

@media screen and (max-width: 1000px) and (max-height: 800px) {
    .pre-footer-container {
        height: 100vh;
    }

}
@media screen and (max-width: 1024px) and (max-height: 1000px){
    .pre-footer-container {
        height: 100%;
    }
}