
.geral-container-part4 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    background: url('../../../../assets/textura.png') no-repeat center center;
    background-color: #4AB8A8;
    background-size: cover;
    background-blend-mode: soft-light;
}

.left-container-part4 {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: blue; */
}

.right-container-part4 {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-container-part4 img {
    height: auto;
    width: 50%;
    /* transition: transform 400ms; */
}

/* .right-container-part4 img:hover {
    transform: scale(1.08);
} */

.left-text-container-part4 {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
}

.left-text-container-part4 label:first-child{
    font-family: Agenda-Bold;
    font-size: 5rem;
    color: white;
    line-height: 4.2rem;
}

.left-text-container-part4 label:last-child {
    font-size: 3.5rem;
    color: white;
    font-family: Euphemia UCAS;
    text-align: start;
}

@media screen and (max-width: 1024px) {
    .geral-container-part4 {
        flex-direction: column;
        height: 100vh;
    }
    .left-container-part4 {
        margin-top: 20px;
    }

    .left-container-part4,
    .right-container-part4 {
        width: 100%;
    }

    .right-container-part4 {
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .right-container-part4 img {
        width: 50%;
        height: auto;
    }

    .hide-rps {
        display: none;
    }
}

@media screen and (max-width: 430px) {
    .geral-container-part4 {
        height: 100%;
    }

    .left-container-part4 label:last-child {
        align-content: center;
    }
}

@media screen and (max-height: 600px){
    .geral-container-part4 {
        height: 100%;
    }
}

@media screen and (max-width: 1024px) and (max-height: 1000px){
    .geral-container-part4 {
        height: 100%;
    }
    
    .right-container-part4 img {
        height: 60%;
    }
}