@font-face {
    font-family: NexaRegular;
    src: url('./assets/font/NexaRegular.otf');
}

@font-face {
    font-family: Nexa-Bold;
    src: url('./assets/font/Nexa-Bold.otf');
}

@font-face {
    font-family: Agenda-Bold;
    src: url('./assets/font/Agenda-Bold.ttf');
}

@font-face {
    font-family: NexaLight;
    src: url('./assets/font/NexaLight.otf');
}

@font-face {
    font-family: NexaHeavy;
    src: url('./assets/font/Nexa-Heavy.ttf');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: NexaRegular,sans-serif;
}

html {
    font-size: 62.5%;
}
 