.celulares {
    height: 375px;
    width: 410px;
}
.title-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
}

.left-container{
    /* padding: 100px; */
    height: 90vh;
    width: 50%;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero {
    width: 100%;
    display: flex;
    padding-left: 100px;
    align-items: center;
    justify-content: center;
}

.cartaoFrota {
    position: relative;
    top: 60px;
    left: -100px;
    height: 244px;
    width: 272px;
}

.left-container label {
    color: #007881;
    margin-bottom: 10%;
    font-family: Agenda-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 4rem;
    line-height: 4rem;
}

.left-container label span {
    font-family: 'Euphemia UCAS';
    color: black;
    font-weight: 400;
    font-size: 3rem;
    line-height: 2rem;
}

@media screen and (max-width: 900px) {

    .left-container {
        display: none;
    }
}

@media screen and (max-height: 650px) {
    .left-container{
        padding-top: 50px;
    }
}