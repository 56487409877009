.geral-container-part1 {
    display: flex;
    /* background-color: #03656EBF; */
    background:  url('../../../../assets/caminhaobg.png') no-repeat center center, linear-gradient(#146065 0%,#03656E 100%) no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 110vh;
    width: 100vw;
    justify-content: space-evenly;
    align-items: center;
    background-blend-mode: lighten;
}

.content-container {
    padding: 17px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
}

.content-container p {
    width: 100%;
    font-family: Nexa-Bold;
    font-size: 3rem;
    line-height: 3.5rem;
    align-items: center;
    text-align: start;
}

.container-whatsapp {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    padding: 25px;
    max-width: 90%;
    width: 60%;
    align-items: center;
    justify-content: space-around;
}

.container-whatsapp .content-container .text-container-whatsapp {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-whatsapp{
    border-top: none;
    border-left: none;
    border-right: none;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 2.5rem;
}


.left-container-part1 {
    /* background: blue; */
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-container-part1 {
    /* background: red; */
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-container-part1 label {
    margin-bottom: 50px;
}

.button-primary {
    border-radius: 42px;
    border: none;
    width: max-content;
    max-width: 400px;
    width: 90%;
    height: 7.3rem;
    font-size: 2rem;
    font-family: NexaRegular;
    background-color: #4AB8A8;
    color: white;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    align-items: center;
    padding-top: 6px;
    cursor: pointer;
}

.top-text-frotabank-part1{
    font-size: 5.5rem;
    font-family: Agenda-Bold;
    font-weight: bold;
    color: white;
    line-height: 6.7rem;
}

.bottom-text-frotabank-part1{
    font-family: Agenda-Bold;
    font-size: 3.0rem;
    line-height: 3.9rem;
    color: white;
    margin: 0px;
}


.arrow-avancar {
    /* max-height: 50px; */
    max-width: 50px;
    font-size: 50px;
}

.container-whatsapp input::placeholder {
    font-size: 1.8rem;
}

@media screen and (max-height: 650px) {
    .container-whatsapp {
        width: 100%;
    }
}

@media screen and (max-height: 500px) {
    .geral-container-part1 {
        height: 110vh;
    }
}

@media screen and (max-height: 950px) and (max-width: 1280px) {
    .container-whatsapp {
        width: 100%;
    }
}


@media screen and (max-width: 1200px) {
    html {
        font-size: 60%;
    }
    .geral-container-part1 {
        height: 100%;
        padding-bottom: 30px;
        padding-top: 30px;
        flex-direction: column;
    }
    .left-container-part1 {
        width: 100%;
    }

    .right-container-part1 {
        width: 100%;
    }

    .container-whatsapp {
        margin-top: 20px;
        width: 70%;
        /* height: auto; */
        margin-bottom: 30px;
        /* background-color: red; */
    }
}

@media screen and (max-width: 800px) {
    
}

@media screen and (max-width: 650px) {
    html{
        font-size: 40%;
    }
    .geral-container-part1{
        height:100%;
        
    }

    .container-whatsapp{
        width: 90%;
    }
}

@media screen and (max-width: 411px) {
    html {
        font-size: 30%;
    }
    .geral-container-part1 {
        height: 100%;
    }

    .container-whatsapp .content-container {
        padding: 0px;
    }

    .container-whatsapp {
        /* padding: 15px; */
        width: 75%;
    }

    .left-container-part1 {
        margin: 20px;
        padding-left: 30px;
        padding-bottom: 20px;
        height: 100%;
    }

    .arrow-avancar {
        font-size: 30px;
    }
}

@media screen and (max-width: 300px){
    .container-whatsapp {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) and (max-height: 1000px){
    .geral-container-part1 {
        height: 100%;
    }
}