header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav {
    padding-right: 20px;
}

.logo {
    margin-right: 0 auto;
    cursor: pointer;
    height: 50px;
    width: 181px;
    padding-left: 10px;
    margin: 17px;
}

.nav-links {
    padding-top: 25px;
    list-style: none;
}

.nav-links li {
    display: inline;
    padding: 0px 10px;
}

.nav-links li a {
    transition: all 0.3 ease 0s;
    text-decoration: none;
    color: #037983;
    font-size: 1.7rem;
    font-family: NexaRegular;
}

.nav-links li a:hover {
    color: black;
}

.menu-section img {
    display: none;
}

#star-icon {
    display: none;
}

.link-pag-inicial {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}
#pagInicial {
    color: #4AB8A8;
}

@media (max-width: 750px) {
    nav ul {
        display: none;
    }

    .logo {
        margin-top: 4px;
        height: 50px;
        width: 120px;
    }

    .one,
    .two,
    .three {
        background-color: #037983;
        height: 3px;
        width: 100%;
        margin: 6px auto;
        transition-duration: 0.4s;
    }

    .nav-links {
        width: 100%;
    }

    .menu-toggle {
        margin-top: 20px;
        width: 40px;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
    }

    .menu-section.on img {
        display: flex;
        position: absolute;
        top: 0;
        width: 100%;
        height: 5px;
    }

    .menu-section.on {
        position: absolute;
        top: 0;
        /* left: 0px; */
        right: 0;
        /* width: 100vw; */
        /* height: 100vh; */
        width: 75vw;

        background-color: white;

        z-index: 20;
        border-bottom-left-radius: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 1px 1px 22px 1px black;
    }

    .menu-section.on nav {
        display: block;
    }

    .menu-section.on .menu-toggle {
        position: absolute;
        right: 20px;
        top: 0;
    }

    .menu-section.on .one,
    .menu-section.on .two,
    .menu-section.on .three {
        display: none;
    }

    /* .menu-section.on .menu-toggle .one {
        transform: rotate(45deg) translate(8px, 7px);
    }

    .menu-section.on .menu-toggle .two {
        opacity: 0;
    }

    .menu-section.on .menu-toggle .three {
        transform: rotate(-45deg) translate(7px, -5px);
    } */

    .menu-section.on nav ul {
        padding-top: 20px;
        text-align: center;
        display: block;
        list-style: none;
    }

    .menu-section.on .nav-links:last-child {
        padding-bottom: 30px;
    }

    #star-icon {
        display: flex;
        padding-top: 2px;
        font-size: 25px;
        color: #037983;
    }

    .menu-section.on nav ul a {
        color: #03656E;
        /* margin-bottom: 30px; */
        padding: 10px;
        /* width: 100vw; */
        /* height: 60px; */
        text-align: start;
        transition-duration: 0.5s;
        font-size: 3rem;
        line-height: 4rem;
        display: block;
        text-decoration: none;
    }
}

@media screen and (max-width: 430px){
    .menu-section.on .one .three {
        margin-top: 30px;
        padding-left: 10px;
    }

    .menu-section.on .nav-links ul li a{
        font-size: 4rem;
    }
}