.contato-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.atendimento-contato-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  box-shadow: 1px 3px grey;
}

.atendimento-contato-mobile label {
  font-size: 3.1rem;
  /* line-height: 3.2rem; */
  color: black;
  font-family: NexaHeavy;
}

.numero-contato-mobile {
  background-color: white;
  width: 100vw;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numero-contato-mobile label{
  color: #037983;
  font-size: 3.6rem;
  line-height: 4.2rem;
  font-family: Agenda-Bold;
}

.email-contato-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4AB8A8;
  padding-top: 2px;
  margin-bottom: 2px;
  width: 100vw;
}

.email-contato-mobile {
  font-size: 2rem;
  color: white;
  font-family: NexaRegular;
}